<template>
  <section class="section section--white-green section--testimonials">
    <div class="container parent_testimonial_container pt-5">
      <h3 class="font-body-large viridian text-centered mb-lg-5 section--title-small">
        {{ forProviders ? 'Providers' : 'Parents' }} love us
      </h3>

      <transition :name="transitionName" mode="out-in">
        <div class="parent_testimonial" :key="visibleQuote">
          <div class="parent_testimonial__quote">
            <img src="https://res.cloudinary.com/kinside/image/upload/v1692192368/quote.svg">
          </div>

          <p class="parent_testimonial__body" v-html="visibleQuotes[visibleQuote].quote"></p>

          <div class="parent_testimonial__author">
            {{visibleQuotes[visibleQuote].author}}
            <br />
            {{visibleQuotes[visibleQuote].employer}}
          </div>
        </div>
      </transition>

      <div class="parent_testimonial__navigation" v-if="visibleQuotes.length > 1">
        <button
          @click="previous"
          :disabled="visibleQuote === 0"
          class="parent_testimonial__navigation__previous">
          <span class="fa fa-chevron-left"></span>
        </button>

        <button
          @click="next"
          :disabled="visibleQuote === visibleQuotes.length - 1"
          class="parent_testimonial__navigation__next">
          <span class="fa fa-chevron-right"></span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import shuffle from 'lodash/shuffle';

const parentQuotes = [
  {
    quote: 'I have never experienced this level of service. Kinside has been <span class="coral">phenomenal from start to finish</span>. It is always stressful to find new child care. The platform is easy to work through and straightforward.<br /><br />I want to especially thank my concierge who was positive, communicative, and took the stress out of finding a place that met our needs.',
    author: 'Sarah Z.',
    employer: 'Reddit',
    hideMobile: true,
  },
  {
    quote: '<span class="coral">We\'re so lucky my company has Kinside!</span> This search took a few hours since your app made finding and cataloging matches so easy. It also limited the tours we took since we knew the places were verified and trusted. Last time we wanted to enroll we took weeks to find a place and another couple weeks finding contact info, getting tours, and getting things done.',
    author: 'Hector M.',
    employer: 'Zennify Inc.',
    hideMobile: true,
  },
  {
    quote: 'Thanks so much for all your help with my search, <span class="coral">Kinside was a huge help</span> and I couldn\'t have done it without you!',
    author: 'Wendy W.',
    employer: 'Dropbox',
  },
  {
    quote: '<span class="periwinkle">This is a wonderful service</span>. It is organized and it is saving me so much time.',
    author: 'Kelly A.',
    employer: 'Extend',
  },
  {
    quote: 'Looking for daycare was so time-consuming and I just really struggled to fit it in while on maternity leave. Add to that the fact that it was nearly impossible to find openings for infants in my area and <span class="coral">you can see why I depended on Kinside</span>.',
    author: 'Candice T.',
    employer: 'Howard County Public School System',
  },
];

const providerQuotes = [
  {
    quote: 'We\'ve seen <span class="coral">demonstrable financial impact</span> directly connected to using Kinside within the Wisconsin Early Education Shared Services Network. It\'s an accessible program that providers find <span class="periwinkle">easy to use and financially affordable.</span>',
    author: 'Paula Drew,',
    employer: 'Co-Director Wisconsin Early Education Shared Services Network',
  },
  {
    quote: 'Kinside has been <span class="coral">AMAZING</span> in assisting me to <span class="periwinkle">keep my waitlist organized and filling spots when needed.</span>',
    author: 'Janell Moran,',
    employer: 'Honey Nature Playschool, Mcfarland, WI',
  },
];

export default {
  name: 'user-quotes',
  props: {
    forProviders: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    visibleQuote: 0,
    transitionName: null,
  }),
  computed: {
    quotes() {
      return shuffle(this.forProviders ? providerQuotes : parentQuotes);
    },
    visibleQuotes() {
      if (window.innerWidth < 768) {
        return this.quotes.filter((quote) => !quote.hideMobile);
      }
      return this.quotes;
    },
  },
  methods: {
    previous() {
      this.visibleQuote = (this.visibleQuote - 1 + this.visibleQuotes.length)
        % this.visibleQuotes.length;
      this.transitionName = 'slide-previous';
    },
    next() {
      this.visibleQuote = (this.visibleQuote + 1) % this.visibleQuotes.length;
      this.transitionName = 'slide-next';
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-next-enter-active,
.slide-previous-enter-active,
.slide-next-leave-active,
.slide-previous-leave-active {
  transition: opacity 400ms, transform 400ms;
}

.slide-next-enter,
.slide-previous-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.slide-previous-enter,
.slide-next-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.parent_testimonial_container {
  position: relative;
}

.parent_testimonial {
  padding: 0 0 80px;
  margin: 0 60px;
  text-align: center;

  @media screen and (min-width: 768px) {
    max-width: 85%;
    padding: 0 0 80px;
    margin: auto;
  }

  &__quote {
    padding: 60px 0 40px;
  }

  &__body {
    font-family: var(--serif);
    font-size: 28px;
    line-height: 1.35;

    @media screen and (min-width: 768px) {
      padding: 0 15px;
      font-size: 37px;
      line-height: 1.28;
    }
  }

  &__author {
    margin-top: 50px;
    text-align: center;
    text-transform: uppercase;
  }

  &__navigation {
    button {
      position: absolute;
      top: 284px;
      width: 42px;
      height: 42px;
      font-size: 28px;
      line-height: 42px;
      color: #fff;
      cursor: pointer;
      background: var(--viridian-50);
      border: 0;
      border-radius: 100%;

      @media screen and (min-width: 768px) {
        width: 58px;
        height: 58px;
        line-height: 58px;
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    &__previous {
      left: 10px;

      @media screen and (min-width: 768px) {
        left: 0;
      }
    }

    &__next {
      right: 10px;

      @media screen and (min-width: 768px) {
        right: 0;
      }
    }
  }
}
</style>
